.navbar {
  height: 50px;
  border-bottom: 0.5px solid rgb(248, 242, 242);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;

  .wrapper {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 3px;
    }

    input {
      border: none;
      outline: none;
      background-color: transparent;

      &::placeholder {
        font-size: 13px;
      }
    }

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }

      .avatar {
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
    }
  }
}
